.ms-Dropdown-title {
    border: none;
    padding-left: 0px;
}

.ms-Dropdown:focus::after {
    border: none !important;
    padding-left: 0px !important;
}

.ms-Dropdown .ms-Button {
    height: 10px;
    padding-top: 15px;
    padding-right: 0px;
    font-size: 11px;
    background-color: transparent !important;
}

.ms-Dropdown .ms-Button .ms-Icon {
    line-height: 20px;
}

body {
    overflow-x: hidden;
}

::-webkit-scrollbar {
    width: 5px;
    height: 5px;
}

::-webkit-scrollbar-track {
    background: #eaedf3;
}

::-webkit-scrollbar-thumb {
    background: #676d7d;
    border-radius: 5px;
}

::-webkit-scrollbar-thumb:hover {
    background: #555;
}
